import { Injectable, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import {
  asyncScheduler,
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  merge,
  observeOn,
  scan,
  shareReplay,
  switchMap,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PageService {
  private router = inject(Router);

  private routeLoadRequests$ = this.router.events.pipe(
    map(event => {
      if (event instanceof RouteConfigLoadStart) {
        return 'send';
      }
      if (event instanceof RouteConfigLoadEnd) {
        return 'receive';
      }
      return null;
    }),
  );

  readonly requests$ = new BehaviorSubject<'send' | 'receive' | null>(null);
  private requestCount$ = merge(this.requests$, this.routeLoadRequests$).pipe(
    filter(Boolean),
    scan((c, r) => this.countRequests(c, r), 0),
  );
  readonly loading = toSignal(
    this.requestCount$.pipe(
      map(c => c > 0),
      distinctUntilChanged(),
      observeOn(asyncScheduler),
    ),
  );

  readonly pageLoaded$ = this.router.events.pipe(
    filter(e => e instanceof NavigationEnd),
    switchMap(() => this.requestCount$),
    filter(count => count === 0),
    debounceTime(0),
    shareReplay(1),
  );

  private countRequests(counter: number, r: 'send' | 'receive'): number {
    if (r === 'send') return counter + 1;
    if (r === 'receive') return counter - 1;
    return counter;
  }
}
